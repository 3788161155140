import React from "react"
import { graphql } from "gatsby"

import DefaultTemplate from "../templates/default"

const IndexPage = props => {
  const page = props.data.homepages

  return <DefaultTemplate page={page} bodyClass="page-home stretched x-tcm2" />
}

export const query = graphql`
  query HomepageQuery {
    homepages: graphCmsPage(layout: { in: Index }) {
      ...GraphCmsPage
    }
  }
`

export default IndexPage
